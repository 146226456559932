window.rwd || (window.rwd = {});

;(function($package) {
    var count = -1;

    $package.UIComponent = UIComponent;
    function UIComponent(node) {
        this.node = node;
        this.id = "item"+ ++count;
        $(node)
            .addClass(UIComponent.COMMON_CLASS_NAME)
            .data(UIComponent.COMMON_PROPERTY_NAME, this);
    }
    UIComponent.COMMON_CLASS_NAME = "ui_component";
    UIComponent.COMMON_PROPERTY_NAME = "ui_component";
    UIComponent.prototype.update = function() {
        // empty implementation
    }
    UIComponent.prototype.updateDescendants = function(searchRoot) {
        var $searchRoot = $(searchRoot);
        var descs = this.closestDescendants($searchRoot);
        descs.each(function() {
            var data = $(this).data(UIComponent.COMMON_PROPERTY_NAME);
            data && data.update();
        });
    }
    UIComponent.prototype.closestDescendants = function(searchRoot) {
        var compareNode = this.node;
        var selector = "."+UIComponent.COMMON_CLASS_NAME;
        var result = $(searchRoot).find(selector).filter(function() {
            return $(this).parents(selector)[0] == compareNode;
        });
        return result;
    }
})(rwd);
